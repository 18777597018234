"use client";
import { Box, CssBaseline, ThemeProvider, createTheme, useTheme } from "@mui/material";
import { orange } from "../../components/Theme";

export default function RootLayout({ children }: { children: React.ReactNode }) {
  const theme = useTheme();

  const newTheme = createTheme(theme, {
    palette: {
      background: {
        default: orange[15],
      },
    },
  });
  return (
    <ThemeProvider theme={newTheme}>
      <CssBaseline />

      <Box display={"flex"}>{children}</Box>
    </ThemeProvider>
  );
}
